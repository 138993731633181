import { Dialog, Transition } from "@headlessui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { Fragment, useState } from "react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../styles/swiper.scss";

function ProjectPopup({ isOpen, setIsOpen, selectedItem }) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto bg-white dark:bg-black bg-opacity-50 dark:bg-opacity-50"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-2xl">
                <div className="flex gap-4 md:gap-8 flex-col md:flex-row">
                  <Swiper
                    className="md:basis-1/2 w-full"
                    loop={selectedItem?.images.length > 1 ? true : false}
                    pagination={true}
                    navigation
                    modules={[Scrollbar, Pagination, Navigation]}
                  >
                    {selectedItem?.images.map((image, index) => (
                      <SwiperSlide key={index}>
                        <GatsbyImage
                          className="rounded-md m-1"
                          image={getImage(image.localFile)}
                          alt={selectedItem?.title}
                        ></GatsbyImage>
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <div className="flex flex-col md:basis-1/2 gap-2">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-700  dark:text-gray-200"
                    >
                      {selectedItem?.title}
                    </Dialog.Title>
                    <div>
                      <p className="text-sm text-gray-700  dark:text-gray-200">
                        {selectedItem?.description}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      {selectedItem?.available ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 fill-green-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p className="text-xs text-gray-700  dark:text-gray-200 whitespace-pre-wrap">
                            Produkt dostępny
                          </p>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 flex-shrink-0 fill-black dark:fill-white"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p className="text-xs text-gray-700  dark:text-gray-200 whitespace-pre-wrap">
                            Produkt dostępny na zamówienie
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ProjectPopup;
