import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../styles/swiper.scss";
import { Navigation, Pagination, Scrollbar } from "swiper";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function Hero() {
  const { strapiMainPage } = useStaticQuery(graphql`
    query HeroQuery {
      strapiMainPage {
        hero {
          id
          subtitle
          title
          hero_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FULL_WIDTH
                  transformOptions: { cropFocus: NORTH }
                )
              }
            }
          }
        }
      }
    }
  `);
  const heroes = strapiMainPage.hero;
  return (
    <Swiper
      className="w-full max-w-screen-xl h-60 lg:h-96"
      navigation={true}
      pagination={true}
      modules={[Navigation, Pagination, Scrollbar]}
    >
      {heroes.map((hero) => {
        return (
          <SwiperSlide key={hero.id}>
            <div className="h-60 lg:h-96 flex relative">
              <div className="flex flex-col h-full flex-1 mx-8 xl:mx-16 items-end justify-between md:justify-center flex-grow text-right z-10">
                <div className="text-sm md:text-xl xl:text-2xl font-bold py-2 my-1 md:my-2 px-4 uppercase bg-white text-gray-700 dark:bg-gray-800 dark:text-gray-200">
                  {hero.title}
                </div>
                <div className="text-xs md:text-lg xl:text-xl font-semibold py-2 my-4 px-4 uppercase dark:bg-white dark:text-gray-700 bg-gray-800 text-gray-200 whitespace-pre-wrap">
                  {hero.subtitle}
                </div>
              </div>
              <GatsbyImage
                loading="eager"
                className="!absolute h-full w-full z-0"
                image={getImage(hero.hero_image.localFile)}
                alt={hero.title}
              ></GatsbyImage>
            </div>
          </SwiperSlide>
        );
      })}
      ;
    </Swiper>
  );
}

export default Hero;
