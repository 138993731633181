import React from "react";
import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function About() {
  const { strapiMainPage } = useStaticQuery(graphql`
    query AboutQuery {
      strapiMainPage {
        about {
          title
          text
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 250)
              }
            }
            alternativeText
            caption
          }
        }
      }
    }
  `);
  const picture = strapiMainPage.about.picture;
  return (
    <section
      id="about"
      className="flex flex-col md:flex-row md:gap-x-10 gap-y-5 items-center	 w-full max-w-screen-xl text-gray-700 bg-white dark:text-gray-200 dark:bg-gray-800 p-10"
    >
      <div className="hidden lg:flex flex-grow">
        <GatsbyImage
          objectFit="initial"
          className="rounded-lg transition duration-500 ease-in-out"
          image={getImage(picture.localFile)}
          alt={picture.alternativeText}
          title={picture.caption}
        />
      </div>
      <div className="flex flex-col">
        <div className="font-bold text-lg lg:text-xl dark:text-gray-200 text-gray-700 whitespace-pre-wrap">
          {strapiMainPage.about.title}
        </div>
        <div className="text-md lg:text-lg dark:text-gray-200 text-gray-700 whitespace-pre-wrap">
          {strapiMainPage.about.text}
        </div>
      </div>

      <div className="rounded-md shadow">
        <a
          href="#"
          className="w-full whitespace-nowrap flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-500 dark:bg-indigo-600 dark:hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
        >
          O mnie
        </a>
      </div>
    </section>
  );
}

export default About;
