import React from "react";

function Contact() {
  return (
    <section
      id="contact"
      className="flex flex-row justify-center md:gap-x-10 gap-y-5 w-full max-w-screen-xl text-gray-700  bg-white dark:text-gray-200 dark:bg-gray-800 p-10"
    >
      <a>Zapraszam do kontaktu</a>
    </section>
  );
}

export default Contact;
