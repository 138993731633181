import React from "react";
import Footer from "./footer";
import Header from "./header";

function Layout(props) {
  return (
    <>
      <Header></Header>
      <main className="flex flex-col flex-grow items-center">
        {props.children}
      </main>
      <Footer></Footer>
    </>
  );
}

export default Layout;
