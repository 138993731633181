import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectPopup from "./modals/projectPopup";

function Portfolio() {
  const { strapiMainPage, allStrapiProduct } = useStaticQuery(graphql`
    query PortfolioQuery {
      strapiMainPage {
        portfolio {
          subtitle
          title
        }
      }
      allStrapiProduct(limit: 3) {
        nodes {
          id
          title
          slug
          description
          available
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  aspectRatio: 0.75
                  width: 300
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  `);
  const products = allStrapiProduct.nodes.map((node) => ({
    title: node.title,
    image: node.images[0],
  }));

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const portfolioImages = products.map((product, index) => (
    <SwiperSlide key={index}>
      <GatsbyImage
        objectFit="cover"
        className="m-2 rounded-md"
        image={getImage(product.image.localFile)}
        alt={product.title}
      ></GatsbyImage>
    </SwiperSlide>
  ));

  const portfolioImagesDesktop = products.map((product, index) => (
    <div
      className="shrink m-2 rounded-md"
      onClick={(e) => {
        e.stopPropagation();
        const selectedIndex = index;
        setSelectedItem(allStrapiProduct.nodes[selectedIndex]);
        setIsOpen(true);
      }}
    >
      <GatsbyImage
        objectFit="cover"
        image={getImage(product.image.localFile)}
        alt={product.title}
      ></GatsbyImage>
    </div>
  ));

  return (
    <section
      id="portfolio"
      className="flex flex-col md:flex-row md:gap-x-10 gap-y-5 items-start md:items-center	w-full max-w-screen-xl text-gray-700  dark:text-gray-200 p-10"
    >
      <Swiper
        className="w-full basis-1/2 block md:hidden"
        autoplay={true}
        loop={true}
        onClick={(swiper) => {
          const selectedIndex = (swiper.clickedSlide as HTMLElement).dataset
            .swiperSlideIndex;
          setSelectedItem(allStrapiProduct.nodes[selectedIndex]);
          setIsOpen(true);
        }}
        modules={[Autoplay]}
      >
        {portfolioImages}
      </Swiper>
      <div className="w-full md:basis-1/2 hidden md:flex flex-row">
        {portfolioImagesDesktop}
      </div>
      <div className="flex flex-col basis-1/2 items-start gap-2">
        <div className="font-bold text-lg lg:text-xl dark:text-gray-200 text-gray-700 whitespace-pre-wrap">
          {strapiMainPage.portfolio.title}
        </div>
        <div className="text-md lg:text-lg dark:text-gray-200 text-gray-700 whitespace-pre-wrap">
          {strapiMainPage.portfolio.subtitle}
        </div>
        <div className="rounded-md shadow self-end">
          <a
            href="#"
            className="w-full whitespace-nowrap flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-500 dark:bg-indigo-600 dark:hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
          >
            Portfolio
          </a>
        </div>
      </div>
      <ProjectPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedItem={selectedItem}
      ></ProjectPopup>
    </section>
  );
}

export default Portfolio;
