import React from "react";

function Footer() {
  return (
    <footer className="w-full text-gray-700 bg-white dark:text-gray-200 dark:bg-gray-800">
      <div className="flex flex-row-reverse  max-w-screen-xl px-4 mx-auto md:px-6 lg:px-8">
        <div className="flex flex-col items-end text-sm p-4">
          <div>2021</div>
          <div>
            created by{" "}
            <a href="mailto:marceli.grabowski@outlook.com">Marceli Grabowski</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
