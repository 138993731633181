import * as React from "react";
import About from "../components/index/about";
import Contact from "../components/index/contact";
import Hero from "../components/index/hero";
import Portfolio from "../components/index/portfolio";
import Layout from "../components/shared/layout";

const IndexPage = () => {
  return (
    <Layout>
      <Hero></Hero>
      <About></About>
      <Portfolio></Portfolio>
      <Contact></Contact>
    </Layout>
  );
};

export default IndexPage;
